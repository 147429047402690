export default class Analytics {
  constructor() {
    this.setClick(document.querySelectorAll('a:not(is-set-click)'))
    this.setClick(
      document.querySelectorAll('.js-click-event:not(is-set-click)'),
    )

    window.addEventListener('load', () => {
      const $jsReadingRate = document.querySelectorAll('.js-reading-rate')
      for (let i = 0; i < $jsReadingRate.length; i++) {
        new ReadingRate($jsReadingRate[i])
      }
    })
  }

  setClick(target) {
    for (let i = 0; i < target.length; i++) {
      target[i].addEventListener('click', this.click)
      target[i].classList.add('is-set-click')
    }
  }

  click(e) {
    const category = this.dataset.category
    const label = this.dataset.label || this.href

    console.log('action: click , category:', category, ', label:', label)
    gtag('event', 'click', {
      event_category: category,
      event_label: label,
      non_interaction: true,
    })
  }

  scroll() { }
}

class ReadingRate {
  constructor(el) {
    this.el = el

    this.countVal = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
    this.reach = 0

    this.resize = this.resize.bind(this)
    window.addEventListener('resize', this.resize)
    this.resize()

    this.scroll = this.scroll.bind(this)
    window.addEventListener('scroll', this.scroll, { passive: true })
    this.scroll()
  }

  resize() {
    this.scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop
    this.windowHeight = window.innerHeight
    this.offsetTop = this.el.getBoundingClientRect().top + this.scrollTop
    this.height = this.el.clientHeight
  }

  scroll() {
    if (this.reach > this.countval) return
    this.scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop

    const rate =
      (this.scrollTop / (this.height + this.offsetTop - this.windowHeight)) *
      100

    if (rate >= this.countVal[this.reach]) {
      const val = this.countVal[this.reach]
      console.log('reading rate:', val + '%')
      gtag('event', 'scroll', {
        event_category: 'reading-rate',
        event_label: val + '%',
        non_interaction: true,
      })
      this.reach++
    }
  }
}
