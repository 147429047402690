export default class Ad {
  constructor() {
    // Window幅でモバイル表示かデスクトップ表示か判定
    this.isMobile = window.matchMedia('(max-width: 1023px)').matches

    let env = 'dev'
    if (location.href.indexOf('https://popeyemagazine.jp') >= 0) {
      env = 'prod'
    } else if ((location.href.indexOf('https://stg.popeyemagazine.jp') >= 0) || (location.href.indexOf('http://popeyemagazine.local') >= 0)) {
      env = 'stg'
    }

    // AD Unitまとめ
    this.adUnit = {
      mobile: {
        'rectangle-a': {
          name: '/21687239661/popeye/mobile_rectangle_A',
          size: [300, 250]
        },
        'rectangle-b': {
          name: '/21687239661/popeye/mobile_rectangle_B',
          size: [300, 250]
        },
        'rectangle-c': {
          name: '/21687239661/popeye/mobile_rectangle_C',
          size: [300, 250]
        },
        'overlay': {
          name: '/21687239661/popeye/mobile_overlay',
          size: [375, 59]
        },
        'inread': {
          name: '/21687239661/popeye/mobile_inread',
          size: [302, 250]
        }
      },
      desktop: {
        billboard: {
          name: '/21687239661/popeye/desktop_billboard',
          size: [970, 250]
        },
        rectangle: {
          name: '/21687239661/popeye/desktop_rectangle',
          size: [300, 250]
        }
      }
    }
    this.pageCategory = ''

    window.googletag = window.googletag || {
      cmd: [],
    }
    googletag.cmd.push(() => {
      // window.googletag.pubads().enableSingleRequest()
      // 遅延ロード
      window.googletag.pubads().enableLazyLoad({
        renderMarginPercent: 20,
      })
      // ADが空の場合は閉じる
      window.googletag.pubads().collapseEmptyDivs()
      // 環境ごとのターゲティング
      window.googletag.pubads().setTargeting('popeye_env', [env])
      // 所属カテゴリごとのターゲティング
      window.googletag.pubads().setTargeting('popeye_category', [AD_CATEGORY])
      window.googletag.enableServices()
    })
    this.index = 0

    this.set()
  }

  set() {
    if (AD_CATEGORY !== 'promotion') {
      this.targets = document.querySelectorAll('.js-ad:not(.is-set-ad)')
      for (let i = 0; i < this.targets.length; i++) {
        const id = `ad-${this.index}`
        // AD表示要素の属性から表示するADを指定する
        const unit = this.targets[i].getAttribute('data-ad-unit')
        const device = this.targets[i].getAttribute('data-ad-device')
        if ((this.isMobile && device === 'desktop') || (!this.isMobile && device === 'mobile')) continue
        const name = this.adUnit[device][unit].name
        const size = this.adUnit[device][unit].size
        this.targets[i].id = id
        googletag.cmd.push(() => {
          googletag.defineSlot(name, size, id).addService(googletag.pubads())
          googletag.display(id)
        })

        this.targets[i].classList.add('is-set-ad')
        this.index++
      }
    }
  }
}