import { hoverDynamic } from './hover';

export function drawer() {
  function drawerInit() {
    document.body.classList.remove('is-drawer-active');
    setTimeout(() => {
      document.body.classList.remove('is-disable-scroll-direction');
    }, 100)
    const drawerStyle = document.querySelector('.drawer-color');
    drawer.innerHTML = '';
    if(drawerStyle) drawerStyle.remove();
  }

  // ドロワーハンドラ
  function drawerHandler() {
    document.body.classList.remove('is-nav-active');
    document.body.classList.add('is-drawer-active', 'is-disable-scroll-direction');
    const hov = drawer.querySelectorAll('a, button, .hov');
    if(hov.length) {
      hov.forEach((el,index) => {
        hoverDynamic(el);
      })
    }
  }

  const drawer = document.querySelector('.js-drawer');
  const drawerClose = document.querySelectorAll('.js-drawer-close');
  // TODOドロワー
  const todoPushTrigger = document.querySelectorAll('[data-todo-id]');
  if(todoPushTrigger.length){
    todoPushTrigger.forEach((el, index) => {
      const todoid = el.dataset.todoId;
      el.addEventListener('click', function(e) {
        e.preventDefault();
        if(todoid && todoData) {
          const foundElement = todoData.find((element) => element['id'] == todoid);
          if(foundElement && drawer) {
            //console.log(foundElement);
            const l = foundElement['label']
            const t = foundElement['title']
            const iw = foundElement['imageWEBP']
            const ij = foundElement['imageJPG']
            const ic = foundElement['imageCaption']
            const e = foundElement['embed']
            const te = foundElement['text']
            const bl = foundElement['buttonLabel']
            const bu = foundElement['buttonURL']
            const be = foundElement['buttonExternal']

            if(l){
              const drawerLabel = document.createElement('p');
              drawerLabel.classList.add('rowm-4', 'textalign-center');
              drawerLabel.innerHTML = l;
              drawer.appendChild(drawerLabel);
            }

            if(t){
              const drawerTitle = document.createElement('h4');
              drawerTitle.classList.add('rowm-1', 'textalign-center', 'textsize-large', 'lh1_5', 'font-sans-serif-bold');
              drawerTitle.innerHTML = t;
              drawer.appendChild(drawerTitle);
            }

            if(iw || ij){
              const w = foundElement['imageW']
              const h = foundElement['imageH']
              const drawerImage = document.createElement('img');
              drawerImage.classList.add('rowm-2');
              drawerImage.setAttribute('src', ij);
              drawerImage.setAttribute('loading', 'lazy');
              if(w && h) drawerImage.style.aspectRatio = `${w}/${h}`;
              drawer.appendChild(drawerImage);
            }

            if(e){
              const drawerEmbed = document.createElement('div');
              drawerEmbed.classList.add('rowm-2');
              drawerEmbed.innerHTML = e;
              drawer.appendChild(drawerEmbed);
            }

            if(ic){
              const drawerImageCaption = document.createElement('p');
              drawerImageCaption.classList.add('rowm-0_5', 'text', 'lh1_5', 'textsize-small');
              drawerImageCaption.innerHTML = ic;
              drawer.appendChild(drawerImageCaption);
            }

            if(te){
              const drawerText = document.createElement('div');
              drawerText.classList.add('rowm-2', 'text', 'lh2', 'textsize-regular');
              drawerText.innerHTML = te;
              drawer.appendChild(drawerText);
            }

            if(bl && bu){
              const drawerButtonContainer = document.createElement('p');
              drawerButtonContainer.classList.add('rowm-2', 'textalign-center');
              const drawerButton = document.createElement('a');
              drawerButton.classList.add('button');
              drawerButton.setAttribute('href', bu);
              if(be) drawerButton.setAttribute('target', '_blank');
              drawerButton.innerHTML = bl;
              drawerButtonContainer.appendChild(drawerButton);
              drawer.appendChild(drawerButtonContainer);
            }

            // if(pc || bc) {
            //   const style = document.createElement('style');
            //   style.classList.add('drawer-color');
            //   document.head.appendChild(style);
            //   if(pc){
            //     style.sheet.insertRule(`:root{--drawer-primarycolor: ${pc};}`);
            //   }
            //   if(bc){
            //     style.sheet.insertRule(`:root{--drawer-bgcolor: ${bc};}`);
            //   }
            // }

            drawerHandler();
          }
        }
      })
    })
  }

  if(drawerClose.length){
    drawerClose.forEach((el, index) => {
      el.addEventListener('click', function(e) {
        e.preventDefault();
        drawerInit();
      })
    })
  }
}