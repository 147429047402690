import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

export function swiperCarousel() {
  const homeCarousel = document.querySelectorAll('.js-swiper-carousel');
  if(homeCarousel.length) {
    homeCarousel.forEach((el,index) => {
      const slides = el.querySelectorAll('.swiper-slide');
      if(slides.length > 1) {
        const wrapper = el.querySelector('.swiper-wrapper');
        const next = el.querySelector('.swiper-button-next');
        const prev = el.querySelector('.swiper-button-prev');
        const bullets = el.querySelector('.swiper-pagination-bullets');
        let mySwiper = '';
        let mySwiperBool = '';

        const getSwiperBool = () => {
          const beforeStr = getComputedStyle(el, ":before").content;
          if(beforeStr == '"swiper"') {
            mySwiperBool = true;
            mySwiper = new Swiper(el, {
              navigation: {
                nextEl: next,
                prevEl: prev
              },
              pagination: {
                el: bullets,
                clickable: false,
              },
              slidesPerView: 'auto',
              spaceBetween: 0,
              edgeSwipeThreshold: 100,
              grabCursor: true
            });
          } else {
            mySwiperBool = false;
            if(mySwiper !== '') {
              mySwiper.destroy(true,true);
              wrapper.removeAttribute('style')
            }
          }
        }
        let lastInnerWidth = window.innerWidth;
        let resizeSto = ''
        window.addEventListener("resize", function () {
          this.clearTimeout(resizeSto);
          resizeSto = setTimeout(() => {
            if(lastInnerWidth != window.innerWidth) {
              lastInnerWidth = window.innerWidth;
              getSwiperBool();
            }
          }, 300)
        });
        getSwiperBool()
      }
    })
  }
}