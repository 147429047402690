export function clock() {
  const dateEls = document.querySelectorAll('.js-date');
  const weekEls = document.querySelectorAll('.js-week');
  const timeEls = document.querySelectorAll('.js-time');
  if (dateEls.length || weekEls.length || timeEls.length) {

    function zenkaku2Hankaku(str) {
      return str.replace(/[A-Za-z0-9]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
      });
    }

    // 0~9の数値に0を足して2桁にする関数(例: 0:0:0 -> 00:00:00)
    function formatTime(val) {
      return ("0" + val).slice(-2);
    }

    const getDateTime = () => {
      // 今日の日付データをcurrentDateに格納
      const currentDate = new Date();

      // 年・月・日・曜日を取得
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const date = currentDate.getDate();
      const day = currentDate.getDay();
      const weekday = ["日曜日","月曜日","火曜日","水曜日","木曜日","金曜日","土曜日"];

      // 時間を取得
      const hour = zenkaku2Hankaku(formatTime(currentDate.getHours()));
      const minute = zenkaku2Hankaku(formatTime(currentDate.getMinutes()));
      const second = zenkaku2Hankaku(formatTime(currentDate.getSeconds()));

      if (dateEls.length) {
        dateEls.forEach((el,index)=> {
          el.innerText = zenkaku2Hankaku(`${year}年${month}月${date}日`);
        })
      }
      if (weekEls.length) {
        weekEls.forEach((el,index) => {
          el.innerText = zenkaku2Hankaku(`${weekday[day]}`);
        })
      }
      if (timeEls.length) {
        timeEls.forEach((el,index) => {
          el.innerText = `${hour}:${minute}:${second}`;
        })
      }
    }
    setInterval(getDateTime,1000);
  }
}