import Cookies from 'js-cookie'

export function tab() {
  const groups = document.querySelectorAll('[data-tab-group-container]');
  if (groups.length) {
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      const tabName = group.dataset.tabGroupContainer;
      const isCook = group.dataset.cook;
      let cook = '';
      if (tabName) {
        const triggers = group.querySelectorAll(`[data-tab-trigger][data-tab-group=${tabName}]`);
        const tabs = group.querySelectorAll(`[data-tab][data-tab-group=${tabName}]`);
        if (isCook == 'true') {
          cook = Cookies.get(tabName)
        }

        if (isCook == 'true' && cook !== '') {
          if (triggers.length) {
            for (let i = 0; i < triggers.length; i++) {
              const trigger = triggers[i];
              if (cook == trigger.dataset.tabTrigger) {
                trigger.classList.add('current');
              } else {
                trigger.classList.remove('current');
              }
            }
          }
          if (tabs.length) {
            for (let i = 0; i < tabs.length; i++) {
              const tab = tabs[i];
              if (cook == tab.dataset.tab) {
                tab.classList.remove('display-none');
              } else {
                tab.classList.add('display-none');
              }
            }
          }
        }

        if (triggers.length) {
          for (let i = 0; i < triggers.length; i++) {
            const trigger = triggers[i];
            trigger.addEventListener('click', function (e) {
              e.preventDefault();
              const triggerVal = trigger.dataset.tabTrigger;
              const thisTab = group.querySelector(`[data-tab=${triggerVal}][data-tab-group=${tabName}]`);
              const isToggle = trigger.dataset.tabTriggerToggle;

              if (isToggle == 'true') {
                if(trigger.classList.contains('current')) {
                  trigger.classList.remove('current');
                  if (thisTab) thisTab.classList.add('display-none');
                } else {
                  trigger.classList.add('current');
                  if (thisTab) thisTab.classList.remove('display-none');
                }
              } else {
                for (let i = 0; i < triggers.length; i++) {
                  const _trigger = triggers[i];
                  _trigger.classList.remove('current');
                }
                for (let i = 0; i < tabs.length; i++) {
                  const _tab = tabs[i];
                  _tab.classList.add('display-none');
                }

                trigger.classList.add('current');
                if (thisTab) thisTab.classList.remove('display-none');

                if (isCook == 'true' && tabName && triggerVal !== '') {
                  Cookies.set(tabName, triggerVal, { expires: 3 })
                }
              }
            });
          }
        }
      }
    };
  }
}