import { getOffset } from './_getOffset'

export function map() {
  if(typeof locations !== 'undefined' && locations !== '') {
    const lists = document.querySelector('.locations-list')
    const mapContent = document.querySelector('.map')
    if(locations.posts.length && lists && mapContent) {
      let map;
      let bounds;
      let markers = []; // マーカーを格納するための配列

      async function initMap() {
        const { Map } = await google.maps.importLibrary('maps');

        map = new Map(mapContent, {
          zoom: 12,
          streetViewControl: false,
          mapTypeControl: false,
          styles: mapStyle
        });

        bounds = new google.maps.LatLngBounds();
        const infoWindow = new google.maps.InfoWindow();

        locations.posts.forEach((location, index) => {
          const marker = new google.maps.Marker({
            position: { lat: location.lat, lng: location.lng },
            map: map,
            label: `${index + 1}`
          });

          markers.push(marker); // マーカーを配列に追加

          marker.addListener('click', () => {
            infoWindow.setContent(`
              <p class="rowm-0_5"><strong>${index+1}. ${location.title}</strong></p>
              <p class="rowm-0_5">${location.address}</p>
              <p class="rowm-1"><a href="${location.permalink}" class="button-tiny">More Info</a></p>
            `);
            infoWindow.open(map, marker);
          });

          bounds.extend(marker.position);
        });

        map.fitBounds(bounds);

        // リストアイテムのクリックイベントハンドラを設定
        const locationItems = document.querySelectorAll('.location-item');
        if(locationItems.length) {
          document.querySelectorAll('.location-item').forEach(item => {
            item.addEventListener('click', function(e) {
              e.preventDefault();
              const markerIndex = parseInt(this.getAttribute('data-id'), 10);
              const selectedMarker = markers[markerIndex];
              google.maps.event.trigger(selectedMarker, 'click');
            });
          });
        }
      }

      window.onload = initMap;
    }
  }
}

export function stickyMap() {
  const mapContent = document.querySelector('.map-content')
  if(mapContent) {
    let scTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

    const map = mapContent.querySelector('.map')
    const header = document.querySelector('.header');

    let headerHeight = '';
    let mapContentTop = ''
    let mapContentBottom = ''
    
    const init = () => {
      headerHeight = header.clientHeight;
      const offset = getOffset(mapContent)
      mapContentTop = offset.top - headerHeight;
      mapContentBottom = mapContentTop + mapContent.clientHeight + headerHeight;
    }

    const scroller = ()=> {
      scTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      if(scTop >= mapContentTop) {
        mapContent.classList.add('is-fixed');
        const scBotttom = scTop + window.innerHeight;
        const scBotttomP = scTop + map.clientHeight + headerHeight;
        if(scBotttom >= mapContentBottom) {
          mapContent.classList.add('is-fixed-release');
        } else {
          mapContent.classList.remove('is-fixed-release');
        }
        if(scBotttomP >= mapContentBottom) {
          mapContent.classList.add('is-fixed-release-portrait');
        } else {
          mapContent.classList.remove('is-fixed-release-portrait');
        }
      } else {
        mapContent.classList.remove('is-fixed', 'is-fixed-release');
      }
    }

    init();
    scroller();

    setInterval(() => {
      scTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      init();
      scroller();
    }, 1000)

    window.addEventListener('resize', () => {
      init();
      scroller();
    });

    window.addEventListener('orientationchange', () => {
      init();
      scroller();
    });

    init();

    window.addEventListener('scroll', scroller)
  }
}