const Flickity = require('flickity');

export function marquee() {
  const marquee = document.querySelectorAll('.js-marquee');
  if (marquee.length) {
    for (let i = 0; i < marquee.length; i++) {
      const el = marquee[i];
      let flg = false;

      if (!el.classList.contains('marquee-text')) {
        document.addEventListener('DOMContentLoaded', function () {
          flg = true;
        });
      }

      const sI = setInterval(() => {
        if (el.classList.contains('marquee-text')) {
          if (document.querySelector('html').classList.contains('wf-active')) flg = true;
        }
        if (flg) {
          //
          //   Variables
          //
          //////////////////////////////////////////////////////////////////////

          // Play with this value to change the speed
          let tickerSpeed = 0.7;
          let flickity = null;
          let isPaused = false;

          if (el.dataset.speed) tickerSpeed = el.dataset.speed;

          //
          //   Functions
          //
          //////////////////////////////////////////////////////////////////////

          const update = () => {
            if (isPaused) return;
            if (flickity.slides) {
              if (el.classList.contains('is-reverse')) {
                flickity.x = (flickity.x + tickerSpeed) % flickity.slideableWidth;
              } else {
                flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
              }
              flickity.selectedIndex = flickity.dragEndRestingSelect();
              flickity.updateSelectedSlide();
              flickity.settle(flickity.x);
            }
            window.requestAnimationFrame(update);
          };

          const pause = () => {
            isPaused = true;
          };

          const play = () => {
            if (isPaused) {
              isPaused = false;
              window.requestAnimationFrame(update);
            }
          };

          //
          //   Create Flickity
          //
          //////////////////////////////////////////////////////////////////////

          flickity = new Flickity(el, {
            autoPlay: false,
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
            resize: false,
            selectedAttraction: 0.015,
            friction: 0.25,
            draggable: false,
            cellAlign: 'left'
          });

          flickity.x = 0;


          //
          //   Add Event Listeners
          //
          //////////////////////////////////////////////////////////////////////

          el.addEventListener('mouseenter', pause, false);
          el.addEventListener('focusin', pause, false);
          el.addEventListener('mouseleave', play, false);
          el.addEventListener('focusout', play, false);

          flickity.on('dragStart', () => {
            isPaused = true;
          });

          flickity.on('dragEnd', () => {
            isPaused = false;
          });

          let lastInnerWidth = window.innerWidth;
          window.addEventListener("resize", function () {
            if (lastInnerWidth != window.innerWidth) {
              lastInnerWidth = window.innerWidth;
              setTimeout(() => {
                flickity.resize();
              }, 0)
            }
          });

          //
          //   Start Ticker
          //
          //////////////////////////////////////////////////////////////////////
          setTimeout(update, 300);
          clearInterval(sI);
        }
      }, 300)
    }
  }
}