import {getOffset} from './_getOffset';

export function setPosTop() {
  if(!document.body.classList.contains('is-set-top')){
    const scTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    document.body.dataset.scrollTop = scTop;
    document.body.classList.add(`is-set-top`);
    window.scrollTo(0, 0);
  }
}
export function resetPosTop() {
  if(document.body.classList.contains('is-set-top')){
    const scTop = document.body.dataset.scrollTop
    window.scrollTo(0, scTop);
    document.body.classList.remove(`is-set-top`);
  }
}