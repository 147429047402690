export default class NewsLetter {
  constructor() {
    this.set(document.querySelectorAll('.js-news-letter'))
  }
  set(target) {
    for (let i = 0; i < target.length; i++) {
      new NewsLetterInstance(target[i])
    }
  }
}

class NewsLetterInstance {
  constructor(el) {
    this.el = el
    this.subscribeUrl = this.el.dataset.subscribeUrl;
    this.unsubscribeUrl = this.el.dataset.unsubscribeUrl;

    this.isUnsubscribe = false
    this.isSending = false

    this.setting = this.el.querySelector('.js-news-letter-setting')
    // this.toggle = this.toggle.bind(this)
    // this.setting.addEventListener('click', this.toggle)

    this.$input = this.el.querySelector('.js-news-letter-mail-address')
    this.$submit = this.el.querySelector('.js-news-letter-submit')

    this.submit = this.submit.bind(this)
    this.$submit.addEventListener('click', (e) => {
      e.preventDefault()
      this.submit()
    })
    this.keydown = this.keydown.bind(this)
    this.$input.addEventListener('keydown', this.keydown)
  }

  // toggle(e) {
  //   e.preventDefault()
  //   if (this.isSending) return
  //   if (this.isUnsubscribe) {
  //     this.isUnsubscribe = false
  //     this.el.classList.remove('is-unsubscribe')
  //     this.$submit.value = '登録送信'
  //   } else {
  //     this.isUnsubscribe = true
  //     this.el.classList.add('is-unsubscribe')
  //     this.$submit.value = '解約送信'
  //   }
  // }

  keydown(e) {
    if (e.keyCode === 13) {
      this.submit()
    }
  }

  submit() {
    if (this.isSending) return

    const email = this.$input.value
    if (!email) return

    const formData = new FormData()
    formData.set('email', email)
    const url = this.isUnsubscribe ? this.unsubscribeUrl : this.subscribeUrl

    fetch(url, {
      method: 'POST',
      body: formData,
    }).then((res) => {
      return res.text()
      // return res.json()
    }).then((data) => {
      if (data) {
        this.success()
      } else {
        this.error()
      }
     }).catch((error) => {
      this.error()
    })
    this.isSending = true
    this.$input.value = '送信中'
    this.sending()
  }

  sending() {
    if (!this.isSending) return
    this.$input.value += ' .'
    setTimeout(() => {
      this.sending()
    }, 1000)
  }

  success() {
    this.isSending = false
    this.$input.value = ''
    this.$input.placeholder = `送信完了！${this.isUnsubscribe ? '解約' : '登録'}完了メールをお送りしました。`
  }

  error() {
    this.isSending = false
    this.$input.value = ''
    this.$input.placeholder = '送信失敗 再度お試しください。'
  }
}
