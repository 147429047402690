import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay])

export default class ArticleCarousel {
  constructor() {
    this.index = 0
    const $artcileCarousels = document.querySelectorAll('.js-article-carousel')
    for (let i = 0; i < $artcileCarousels.length; i++) {
      new ArticleCarouselInstance($artcileCarousels[i])
      this.index++
    }
  }
}

class ArticleCarouselInstance {
  constructor(target) {
    this.target = target
    this.container = this.target.querySelector('.swiper-container')
    this.prev = this.target.querySelector('.swiper-button-prev')
    this.next = this.target.querySelector('.swiper-button-next')
    this.pageNation = this.target.querySelector('.swiper-pagination')
    this.id = this.target.dataset.id

    let option = ''

    if (this.target.classList.contains('is-style-belt')) {
      this.currentX = 0
      this.beltWrapper = this.target.querySelector('.blocks-gallery-belt')
      this.items = this.target.querySelectorAll('.blocks-gallery-item')

      for (let i = 0; i < this.items.length; i++) {
        this.beltWrapper.appendChild(this.items[i].cloneNode(true))
      }

      this.beltResetSize = this.beltResetSize.bind(this)
      this.beltRender = this.beltRender.bind(this)
      this.beltTransition = this.beltTransition.bind(this)

      this.now =
        window.performance &&
        (performance.now ||
          performance.mozNow ||
          performance.msNow ||
          performance.oNow ||
          performance.webkitNow)
      this.fps = 30
      this.speed = 3
      this.startTime = this.getTime()

      window.addEventListener('resize', this.beltResetSize)
      this.beltResetSize()

      this.beltRender()
    } else {
      option = {
        loop: true,
        observer: true,
        observeParents: true,
        edgeSwipeThreshold: 100,
        grabCursor: true,
        preloadImages: false,
        pagination: {
          el: this.pageNation,
          type: 'fraction',
        },
        navigation: {
          prevEl: this.prev,
          nextEl: this.next,
        },
        on: {
          slideChange: (e) => {
            gtag('event', 'slide', {
              event_category: this.id,
              event_label: e.realIndex,
              non_interaction: true,
            })
          }
        }
      }
      this.swiper = new Swiper(this.container, option)
    }
  }

  beltResetSize() {
    this.maxX = 0
    for (let i = 0; i < this.items.length; i++) {
      this.maxX += this.items[i].clientWidth
    }
    this.frameLength = this.maxX / this.speed
  }

  getTime() {
    return (this.now && this.now.call(performance)) || new Date().getTime()
  }

  beltRender() {
    this.beltTransition()
    requestAnimationFrame(this.beltRender)
  }

  beltTransition() {
    this.currentX = ((this.getTime() - this.startTime) / (1000 / this.fps)) % this.frameLength,
      this.beltWrapper.style.transform = `translate3d(-${this.currentX * this.speed}px, 0px, 0px)`
  }
}
