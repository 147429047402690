import anime from 'animejs';
const throttle = require('lodash.throttle');

export function todoCarousel(selector = '.js-todo-carousel-container') {
  const carouselContainer = document.querySelectorAll(selector);
  if(carouselContainer){
    for(let i = 0; i < carouselContainer.length; i++) {
      const target = carouselContainer[i];
      const carousel = target.querySelector('.js-todo-carousel');
      const cell = carousel.querySelectorAll('.js-todo-carousel-cell');
      const previous = target.querySelector('.js-todo-carousel-nav-previous');
      const next = target.querySelector('.js-todo-carousel-nav-next');
      const total = cell.length-1;
      const lastCell = cell[total];
      let PosArr = '';
      let current = 0;
      let flg = true;

      const SET_POS = function () {
        PosArr = new Array;
        for(let i = 0; i <= total; i++) {
          const target = cell[i];
          const targetScrollLeft = target.offsetLeft;
          const targetScrollRight = targetScrollLeft + target.offsetWidth;
          PosArr[i] = [targetScrollLeft, targetScrollRight];
        }
      }

      SET_POS();

      window.addEventListener('resize', throttle(function(){
        SET_POS();
      }, 600), false);

      let anim = anime();
      const CAROUSEL_SCROLLER = (pos) => {
        anim.pause();
        flg = false;
        anim = anime({ 
          targets: carousel,
          scrollLeft: pos,
          duration: 600,
          easing: 'easeOutQuart'
        });
        anim.complete = () => {
          flg = true;
        }
      }

      previous.addEventListener('click', function (e) {
        e.preventDefault();
        if (current == 0) {
          current = total;
        } else {
          current--;
        }
        const target = PosArr[current][0];
        CAROUSEL_SCROLLER(target);
        return false;
      });

      next.addEventListener('click', function (e) {
        e.preventDefault();
        if (current == total) {
          current = 0;
        } else {
          current++;
        }
        const target = PosArr[current][0];
        CAROUSEL_SCROLLER(target);
        return false;
      });
    }
  }
}