// Stylesheets
import './assets/scss/site.scss'

import { hover } from './assets/modules/hover'
import { fluidvideo } from './assets/modules/fluidvideo'
import { trigger } from './assets/modules/trigger'
import { fillHeight } from './assets/modules/fillHeight'
import { clock } from './assets/modules/clock'
import { homeHeaderScroll } from './assets/modules/homeHeaderScroll'
import { stickyHeader } from './assets/modules/stickyHeader'
import { initPhotoSwipeFromDOM } from './assets/modules/photoswipe-init';
import { swiperCarousel } from './assets/modules/swiperCarousel'
import { todoCarousel } from './assets/modules/todoCarousel'
import { drawer } from './assets/modules/drawer'
import { infoTriangle } from './assets/modules/infoTriangle'
import { marquee } from './assets/modules/marquee'
import { marqueeClassic } from './assets/modules/marqueeClassic'
import { map, stickyMap } from './assets/modules/map'
import { scrollNav } from './assets/modules/scrollNav'
import { bounceImage } from './assets/modules/bounceImage'
// import { gathering } from './assets/modules/gathering'
import Ad from './assets/modules/ad'
import Analytics from './assets/modules/analytics'
import ArticleCarousel from './assets/modules/carousel'
import NewsLetter from './assets/modules/newsletter'

const init = () => { 
  trigger()
  fillHeight()
  clock()
  homeHeaderScroll()
  hover()
  fluidvideo()
  stickyHeader()
  initPhotoSwipeFromDOM('.js-pswp-container')
  swiperCarousel()
  drawer()
  todoCarousel()
  infoTriangle()
  marquee()
  marqueeClassic()
  map()
  stickyMap()
  scrollNav()
  bounceImage();
  // gathering()
  new Ad()
  new Analytics()
  new ArticleCarousel()
  new NewsLetter()
}

init()